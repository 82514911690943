<template>
  <a :href="project.projectURL" target="_blank" :aria-label="`${project.name},${project.description}`">
    <div class="flex justify-center m-2" :title="project.showcase === 'github'
      ? 'Check the project on GitHub'
      : project.showcase === 'live demo'
        ? 'Check the live demo'
        : ''
      ">
      <div :class="[
        checkForProjectNames(project.name)
        && 'project-card-mobile',
        'card w-full md:w-96 h-[720px]  dark:bg-dark_theme_base_100 bg-gray-100 shadow-xl hover:-translate-y-2 transition duration-300 cursor-pointer',
      ]">
        <figure>
          <img :src="project.imgURL" :alt="project.name" class="w-full h-48" loading="lazy" />
        </figure>
        <div class="card-body">
          <h2 class="card-title text-lg">
            {{ project.name }}
          </h2>
          <p class="align-middle text-[16px]">{{ project.description }}</p>
          <div :class="[
            numberOfProjectTechnologies > 6 ? 'grid-rows-3 ' : 'grid-rows-2',
            'grid grid-cols-4 mt-2',
          ]">
            <div v-for="technology in technologiesUsed(project)"
              class="flex justify-center w-10 items-center m-1 py-1 !px-[.4rem] hover:-translate-y-1 delay-75 transition">
              <img class="w-[28px] h-[28px]" :src="technology.imageURL" :alt="`${technology.name} icon`"
                :title="technology.name" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </a>
</template>

<script setup lang="ts">
import { useWindowSize } from '@vueuse/core'

import { skills } from '~/constants'

import type { Project } from '../../types/project.type'

const { width } = useWindowSize()

const { project } = defineProps<{
  project: Project
}>()

const projectsToCheck = [
  '3D Model Configurator created with Unlimited3D, React, TypeScript, Tailwind and Vite',
  'MERN + TypeScript authentification app with refresh token logic',
  'MEVN + TypeScript authentification app with refresh token logic and unit tests',
  'MEVN and Quasar CRUD app with JWT Authentification',
  'Express.js countries and cities API',
  'Next.js, Strapi.io, TypeScript and React-Bootstrap restaurants blog',
  'Nuxt.js 2, Strapi.io, TypeScript and BootstrapVue restaurants blog',
]

const checkForProjectNames = (projectName: string) => {
  return projectsToCheck.includes(projectName)
}

const technologiesUsed = (project: Project) =>
  skills.filter((skill) => project.technologies?.includes(skill.name))

const numberOfProjectTechnologies = computed(() => technologiesUsed.length)
</script>
