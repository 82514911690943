<template>
  <div
    class="!grid mx-10 marker:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-8"
  >
    <template v-for="project in projectsToShow">
      <ProjectCard :project="project" />
    </template>
  </div>
</template>

<script setup lang="ts">
import ProjectCard from './Card.vue'
import type { Project } from '~/types/project.type'

const { projects, numberOfShownProjects } = defineProps<{
  projects: Project[]
  numberOfShownProjects: string
}>()

const numberOfProjectsToShow = computed(() =>
  numberOfShownProjects === 'All'
    ? projects.length.toString()
    : numberOfShownProjects
)

const projectsToShow = computed(() =>
  projects.slice(0, parseInt(numberOfProjectsToShow.value))
)
</script>
